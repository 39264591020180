<template>
    <v-col md="8" cols="12">
        <v-row>
            <v-col cols="12" class="pb-0 pt-12">
                Promo or Voucher codes (discount will be applied to balance payment)
                <v-text-field v-model="payload.voucher_code" outlined class="text-hidden"></v-text-field>
            </v-col>
            <!-- <v-col cols="12" class="pb-0">
                Regular price in IDR
            </v-col> -->
            <!-- <v-col cols="12" class="pt-0">
                <h2>Rp {{ currency }}</h2>
            </v-col> -->
        </v-row>
        <v-row>
            <v-col cols="12" class="pb-0">
                <h6>Participant Disclaimer</h6>
            </v-col>
            <v-col cols="12" class="pb-0">
                <a target="_blank" href="https://www.oneworldayurveda.com/program-participants-disclaimer/">Oneworld
                    Ayurveda Participants Disclaimer</a>
            </v-col>
            <v-col cols="12" class="pt-0">
                <a target="_blank" href="https://www.oneworldayurveda.com/privacy-policy/">Oneworld Ayurveda Privacy
                    Policy</a>
            </v-col>
            <v-col cols="12" class="pt-0">
                <v-checkbox v-model="checkbox"
                    label="I have read and agree to the Retreat Participants Disclaimer and Privacy Policy"></v-checkbox>
                <span class="text-span">Note: You will be asked to sign the disclaimer upon arrival at the
                    center.</span>
            </v-col>
        </v-row>
        <!-- <v-row>
            <v-col cols="12" class="pb-0">
                Pay 50% of your Panchakarma in advance
            </v-col>
            <v-col cols="12" class="pt-0">
                <h1>Rp {{ payment }}</h1>
            </v-col>
        </v-row> -->
        <!-- <v-row>
            <v-col cols="12" class="pb-0">
                Payment options <span style="color:#ec0000;">*</span>
            </v-col>
            <v-col cols="12" class="pt-0">
                <v-radio-group v-model="payload.payment" class="pt-0">
                    <v-radio
                        v-for="(method, index) in payment_methods"
                        :key="index"
                        :label="method.show"
                        :value="method.code"
                    ></v-radio>
                </v-radio-group>
            </v-col>
        </v-row> -->
        <!-- <v-row v-if="payload.payment[0]=='else'">
            <v-col cols="12" class="pb-0">
                <h6>Please enter your question below</h6>
                <v-textarea
                    outlined
                    v-model="payload.question"
                ></v-textarea>
            </v-col>
        </v-row>
        <v-row v-else-if="payload.payment[0]=='bank'">
            <v-col cols="12" class="pb-0">
                <p>Please note this information. We will also email it to the email address you provided.</p>
                <p>
                Beneficiary Bank: BANK CENTRAL ASIA <br>
                Beneficiary Name: PT ONE WORLD RETREAT <br>
                Beneficiary Address: Jl. By Pass Ngurah Rai 101, SANUR, 80227, BALI, INDONESIA <br>
                Bank account number: 0402174445 <br>
                Address: Jl. HASANUDIN NO. 58, DENPASAR, 80119, BALI, INDONESIA <br>
                SWIFT CODE: CENAIDJA
                </p>
                <p>Our reservations staff will confirm your booking and will send you a copy of the invoice as soon as we receive payment.</p>
            </v-col>
        </v-row> -->
        <v-row>
            <v-col>
                <v-btn :disabled="!valid" block depressed large color="primary" :loading="loading"
                    @click="paymentProcess(payload)">
                    Submit
                </v-btn>

                <!-- <v-snackbar v-model="snackbar">
                    {{ text }}

                    <template v-slot:action="{ attrs }">
                        <v-btn color="primary" text v-bind="attrs" @click="snackbar = false">
                            Close
                        </v-btn>
                    </template>
</v-snackbar> -->

                <v-dialog v-model="dialog" max-width="400">
                    <v-card>
                        <v-card-title class="text-h5"></v-card-title>
                        <v-card-text>
                            {{ text }}
                        </v-card-text>
                        <v-card-actions>
                            <v-btn color="primary" text @click="dialog = false">
                                Close
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <!-- <v-dialog eager max-width="500" v-model="dialog">
                    <v-card height="600">
                        <iframe v-if="iframeReady" :src="iframe" frameborder="0"></iframe>
                    </v-card>
                </v-dialog> -->
            </v-col>
        </v-row>
    </v-col>
</template>

<script>
import axios from 'axios'
export default {
    name: 'PCPaymentShow',
    props: {
        paymentData: Object,
        valid: Boolean,
        coba: Object
    },
    data() {
        return {
            checkbox: false,
            loading: false,
            snackbar: false,
            text: '',
            dialog: false,
            iframeReady: false,
            payload: this.paymentData,
            payment_methods: [
                {
                    show: 'Credit card',
                    code: ["CREDIT_CARD"]
                },
                {
                    show: 'Bank transfer',
                    code: ['bank']
                },
                {
                    show: 'Do you still have question or would like more information?',
                    code: ['else']
                },
            ],
        }
    },
    methods: {
        async paymentProcess(payload) {
            try {
                this.coba.validate()
            } catch (error) {
                this.text = 'Oops! You’ve missed some required information. \n Please fill it out before proceeding.'
                this.dialog = true
            }
            if (this.coba.validate() != false && this.payload.payment != "" && this.checkbox == true && this.payload.price > 0) {
                this.loading = true
                await axios.post('https://api.oneworldbali.com/owr/pc-payment', payload).then(response => {
                    // await axios.post('http://localhost:3344/owr/pc-payment', payload).then(response => {
                    if (response.status == 200 && response.data.status == 'DOKU') {
                        this.iframe = response.data.link + '?view=iframe'
                        this.dialog = true
                        this.iframeReady = true
                    } else {
                        window.location.replace(response.data.link)
                    }
                }).catch(error => {
                    console.log(error.response)
                });
                this.loading = false
            } else if (this.coba.validate() != false && this.payload.payment == "") {
                this.text = 'You do not choose yet the payment method'
                this.dialog = true
            } else if (this.checkbox == false) {
                this.text = 'Please check the program disclaimer box before proceeding.'
                this.dialog = true
            } else {
                this.text = 'Oops! You’ve missed some required information. Please fill it out before proceeding.'
                this.dialog = true
                this.valid
            }
        }
    },
    computed: {
        currency() {
            return new Intl.NumberFormat('en-US').format(this.paymentData.price);
        },
        // payment() {
        //     return new Intl.NumberFormat('en-US').format(this.paymentData.price/2);
        // }
    },
}
</script>

<style></style>